.footer__line {
  margin-left: -8em;
  margin-top: 98px;
}

.small__footer__line__div{
    position: relative;
}

.small__footer__line {
  display: none;
}

.footer_sec {
  margin-top: 1em;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 2em;
  margin-bottom: 2em;
}

.footer__social__icons {
  color: #fff;
}

.footer_links {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.footer_links a {
  text-decoration: none;
  color: var(--primary-text-color);
  font-size: 1.1rem;
}

.col_3 {
  text-align: right;
  position: relative;
}

.footer__social__icons {
  display: flex;
  justify-content: flex-end;
  gap: 1em;
  margin-bottom: 2em;
}

.icon {
  font-size: 2.5rem;
  box-sizing: border-box;
  cursor: pointer;
  padding: 5px;
  background: linear-gradient(
    90deg,
    var(--secondary-bg-color) 0%,
    var(--asset-bg-color) 100%
  );
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.icon__wrap{
    position: absolute;
    top: 1.8px;
    left: 2.0px;
  background: black;
  border-radius: 50%;
  width: 43px;
  height: 43px;
  text-align: center;
}

.icon__wrap__wrap{
    position: relative;
    background: linear-gradient(
    45deg,
    var(--secondary-bg-color) 0%,
    var(--asset-bg-color) 100%
  );
  border-radius: 50%;
  padding: 5px;
  width: 46px;
  height: 46px;
}

.col_3 p {
  color: var(--primary-text-color);
  font-size: 1.1rem;
  margin-bottom: 1.5em;
}

.col_3 input {
  font-size: 1.1rem;
  border: none;
  border-bottom: 2px solid var(--asset-bg-color);
  background-color: transparent;
  color: var(--primary-text-color);
  padding: 0.32em;
}

.col_3 input:focus {
  border: none;
  border-bottom: 2px solid var(--asset-bg-color);
  outline: none;
  padding: 0.32em;
}

.col_3 button {
  font-size: 1.25rem;
  padding: 0.3em 1.2em;
  background: transparent;
  color: var(--asset-bg-color);
  border: 2px solid;
  border-image: linear-gradient(237.84deg, #000000 7.73%, #551A8B 37.18%, #C71585 56.75%, #000000 96.75%) 1;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-left: 0.5em;
  cursor: pointer;
}

.mobile__footer__social__icons {
  display: none;
}



@media only screen and (max-width: 800px) {
  .container {
    width: 90%;
    margin-inline: auto;
  }
  .footer_sec {
    flex-direction: column;
    justify-content: center;
  }

  .footer_links {
    margin-top: 1em;
    gap: .8rem;
    margin-bottom: 2em;
    opacity: 0.8;
  }

  .footer__line {
    display: none;
  }

  .small__footer__line {
    display: block;
    margin-left: -15em;
  }

  .col_3 {
    text-align: justify;
  }

  .footer__social__icons {
    display: none;
  }

  .mobile__footer__social__icons {
    display: flex;
    justify-content: center;
    gap: 1em;
    margin-top: 3em;
  }

  .col_3 button {
    margin: 0;
  }
}

@media only screen and (max-width: 500px) {
  .footer_sec {
    margin-top: 0em;
    padding: 0;
  }

  .small__footer__line {
    display: block;
    margin-left: -23em;
    margin-bottom: 0;
  }

  .col_3 input,
  .col_3 button {
    font-size: 1.05rem;
  }


}
