.vector__images{
    position: relative;
    border: 1px solid transparent;
}

.polygon_1{
    position: absolute;
    left: -30px;
    top: 20px;
    z-index: -1;
}

.image_4{
    position: absolute;
    left: -12px;
    top: -5px;
}

.line_1{
    position: absolute;
    left: -80px;
    top: 90px;
    z-index: -2;
    
}

.image_3{
    position: absolute;
    left: 150px;
    top: 200px;
}

.polygon_2{
    position: absolute;
    left: 150px;
    top: 202px;
    z-index: -1;
}

.image_6{
    position: absolute;
    left: -18px;
    top: 415px;
}

.polygon_3{
    position: absolute;
    left: -36px;
    top: 400px;
    z-index: -1;
}

.line_2{
    position: absolute;
    top: 90px;
    right: -80px;
    z-index: -2;
}

.image_7{
    position: absolute;
    right: 130px;
    top: 5px;
}

.polygon_4{
    position: absolute;
    right: 150px;
    top: 15px;
    z-index: -1;
}

.image_5{
    position: absolute;
    right: -15px;
    top: 360px;
}

.polygon_5{
    position: absolute;
    right: -41px;
    top: 375px;
    z-index: -1;
}

.ellipse_1{
    position: absolute;
    left: 320px;
    top: 100px;
}

.ellipse_2{
    position: absolute;
    left: 450px;
    top: 500px;
}

.ellipse_3{
    position: absolute;
    right: 150px;
    top: 400px;
}

.vector__images__text{
    text-align: center;
    margin-top: 11em;
    color: var(--primary-text-color);
}

.vector__images__text h1{
    font-size: 2.5rem;
    font-weight: bolder;
}

.vector__images__text p{
    font-size: 1.15rem;
    margin-top: .5em;
}

.purple__text{
    color: var(--secondary-bg-color);
}

.pink__text{
    color: var(--asset-bg-color);
}

.team__col{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5em;
}

.team__cols{
    margin-top: 25em;
}

.team__work__with__us{
    text-align: center;
    color: var(--primary-text-color);
    margin-top: 15em;
    margin-bottom: 8em;
}

.team__work__with__us p{
    font-size: 1.25rem;
    margin-bottom: 2em;
}

.mobile__line1{
    display: none;
    z-index: -2;
}

.mobile__line2{
    display: none;
    z-index: -2;
}


@media only screen and (max-width: 1000px) {
    .team__col{
        gap: 2em;
    }

    .vector__images__text h1{
        font-size: 2rem;
    }
}

@media only screen and (max-width: 500px){
    .vector__images__text h1{
        font-size: 2rem;
    }

    .vector__images{
        margin-top: 3em;
    }

    .polygon_1{
        left: 10px;
        width: 20%;
    }

    .image_4{
        width: 80px;
        left: 20px;
        top: 1px;
    }

    .line_1, .line_2{
        display: none;
    }

    .image_3{
        position: absolute;
        left: 2px;
        top: 359px;
        width: 17%;
    }
    
    .polygon_2{
        position: absolute;
        left: 5px;
        top: 362px;
        z-index: -1;
        width: 22%;
    }

    .polygon_3{
        left: -25px;
        width: 20%;
        top: 520px;
    }

    .image_6{
        width: 15%;
        top: 520px;
    }

    .polygon_4, .image_7{
        display: none;
    }


    .ellipse_1{
        width: 7%;
        top: 50px;
        left: 200px;
    }

    .ellipse_3{
        width: 7%;
        right: 100px;
    }

    .polygon_5{
        width: 18%;
        right: -18px;
        top: 405px;
    }

    .image_5{
        width: 18%;
        right: -3px;
        top: 387px;
    }


    .mobile__line1{
        position: absolute;
        display: block;
        z-index: -2;
        top: 50px;
        left: -15px;
    }

    .mobile__line2{
        position: absolute;
        display: block;
        right: -20px;
    }

    .team__col{
        flex-direction: column;
        gap: 5em;
    }

    .team__cols{
        margin-bottom: 8em;
    }

    .team__work__with__us{
        display: none;
    }
}