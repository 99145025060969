nav {
  position: sticky;
  top: 0;
  z-index: 2;
  backdrop-filter: blur(10px);
  height: 70px;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
  margin-inline: auto;
}

.logoCtn {
  display: flex;
  height: 70px;
  width: 70px;
}

.logo {
  /* width: 6%;
  min-width: 6%; */
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;

}

.nav__toggler {
  cursor: pointer;
  display: none;
}

li {
  float: left;
}

li a,
.dropbtn {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  cursor: pointer;
}

.dropbtn {
  display: flex;
  align-items: center;
  gap: .5em;
}

.dropbtn svg {
  display: block;
}

li.dropdown {
  display: inline-block;
}

.dropbtn:hover {
  color: var(--secondary-bg-color);
}

.dropdown_content a {
  color: var(--primary-text-color);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown:hover .dropdown_content {
  display: block;
}

.dropdown_content {
  display: none;
  position: absolute;
  background-color: black;
  min-width: 130px;
  border-radius: 20px;
  border: 2px solid var(--asset-bg-color);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

@media screen and (max-width: 880px) {
  .dropbtn {
    padding: 0;
  }
}

.navbar {
  width: 90%;
}

.links a {
  text-decoration: none;
  color: white;
  font-size: 1em;
}
.links a.active,
.dropbtn.active {
  color: var(--asset-bg-color);
}

.links a:hover,
.dropbtn:hover {
  color: color-mix(in lab, var(--asset-bg-color) 70%, #fff 30%) ;
}

.nav__toggler {
  cursor: pointer;
  display: none;
}

@media screen and (max-width: 880px) {
  .nav__toggler {
    display: block;
  }
}


.ctabtn {
  transition: scale 0.2s ease-in-out;
}
.ctabtn:hover {
  scale: 1.1;
}