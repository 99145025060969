@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap&family=Source+Sans+3&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	line-height: 1.15; /* 1 */
	/* -webkit-text-size-adjust: 100%; 2 */
	-webkit-text-size-adjust: none; /* for iOS Safari */
	text-size-adjust: none; /* for other mobile browsers */
}

@media (prefers-reduced-motion: no-preference) {
	html {
		scroll-behavior: smooth;
	}
}

main {
	display: block;
}

:root {
	--primary-bg-color: black;
	--asset-bg-color: #c71585;
	--secondary-bg-color: #551a8b;
	--primary-text-color: #fff;
	--toastify-color-success: linear-gradient(90deg, #7638af 0%, #c71585 91.42%);
	--toastify-color-light: #121212;
}

.primary-gradient {
	background: linear-gradient(90deg, #7638af 0%, #c71585 91.42%);
}

.card-gradient {
  position: relative;
  background: black;
  z-index: 10;
}

.login-bg {
	background-image: url("/src/assets/Login_Assets/LoginImg.png");
	background-size: cover;

}

/* .card-gradient::before {
    content: '';
    position: absolute;
    top: -3px;
    left: -3px;
    right: -3px;
    bottom: -3px;
    z-index: -1;
    background-image: linear-gradient(237.84deg, #000000 7.73%, #551a8b 37.18%, #c71585 56.75%, #000000 96.75%);
} */

.border-gradient-styling {
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  background-image: linear-gradient(237.84deg, #000000 7.73%, #551a8b 37.18%, #c71585 56.75%, #000000 96.75%);
  border-radius: 15px;
}


/* rotate sidebar logo */
@keyframes slowRotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: slowRotate 5s linear infinite;
}

.navlink-test.active {
	background: #000;;
}


/* Dashboard cards backgrounds */
.dashboard-card-bg1 {
	background-image: url("/src/assets/Dashboard_assets/dash1.png");
	background-size: cover;
	height: 140px;
	width: 232px;
}

.dashboard-card-bg2 {
	background-image: url("/src/assets/Dashboard_assets/dash2.png");
	background-size: cover;
	height: 140px;
	width: 232px;
}

.dashboard-card-bg3 {
	background-image: url("/src/assets/Dashboard_assets/dash3.png");
	background-size: cover;
	height: 140px;
	width: 232px;
}

.dashboard-card-bg4 {
	background-image: url("/src/assets/Dashboard_assets/dash4.png");
	background-size: cover;
	height: 140px;
	width: 232px;
}


body {
	margin: 0;
	min-height: 100vh;
	background-color: var(--primary-bg-color);
	overflow-x: hidden;
	-webkit-font-smoothing: antialiased;
	font-family: "Source Sans 3", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Roboto Condensed", sans-serif;
}

.purple-text {
	color: var(--secondary-bg-color);
}

.pink-text {
	color: var(--asset-bg-color);
}

.navbar_links {
	display: flex;
	gap: 3.5em;
	align-items: center;
	justify-content: center;
}

.navbar_links a {
	text-decoration: none;
	color: white;
	font-size: 1em;
}

.navbar_links a.active {
	color: var(--asset-bg-color);
}

.nav__toggler {
	cursor: pointer;
	display: none;
}

/* Styling to accomodate tailwind deficiencies */
.overlay-class {
  display: none;
	transition: all 0.5s ease-in-out;
}

.hover-class:hover .overlay-class {
  display: block;
	transition: all 0.5s ease-in-out;
}


@media screen and (max-width: 1090px) {
	.navbar_links {
		gap: 1.5em;
	}
}

@media screen and (max-width: 880px) {
	.nav__toggler {
		display: block;
		position: fixed;
		top: 2.5vh;
		right: 2.5vw;
		scale: 1.5;
	}

	.navbar_links {
		position: fixed;
		top: 9vh;
		left: 110%;
		width: 50%;
		min-width: 212px;
		background-color: black;
		flex-direction: column;
		gap: 1em;
		align-items: flex-start;
		padding: 1em;
		transition: 0.5s ease-in;
		z-index: 2;
	}

	.nav__active.navbar_links {
		left: 60%;
	}
}

@media screen and (max-width: 450px) {
	.navbar_links {
		width: 65%;
	}
	.nav__active.navbar_links {
		left: 40%;
	}
}

img {
	display: block;
}

.blog_1 {
	color: var(--primary-text-color);
}

.App {
	position: relative;
	overflow-x: hidden;
}

.fixctn {
	width: 90%;
	margin-inline: auto;
}

html {
	scrollbar-width: thin;
	scrollbar-color: var(--asset-bg-color) #222;
}

html::-webkit-scrollbar {
	width: 1em;
}

html::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

html::-webkit-scrollbar-thumb {
	background-color: darkgrey;
	outline: 1px solid slategrey;
}

/* Sidebar styling */

.hamburger {
  display: none;
  z-index: 999;
}

@media (max-width: 768px) {
  .hamburger {
    display: block;
    cursor: pointer;
    padding: 10px;
    color: white;
    position: fixed;
    right: 1rem;
    top: 2vh;
    z-index: 999;
    background: rgba(27, 28, 30, 1);
    border-radius: 5px;
  }

  .bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: var(--asset-bg-color);
  }

  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

}
