.card {
  display: flex;
  color: var(--primary-text-color);
  align-items: center;
  justify-content: center;
  min-width: 30%;
  /* margin-top: 2em; */
  padding: 1em;
}

.card.vertical {
  flex-direction: column;
}

.card.noPadding {
  padding: 0;
}

.imgCtn {
  height: 100%;
  width: 40%;
  min-width: 40%;
  border-radius: 10px;
  overflow: hidden;
}

.card.vertical .imgCtn {
  width: 100%;
  height: 40%;
  min-height: 40%;
}

.imgCtn .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.card__small__text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1em;
  gap: 1em;
  margin-bottom: 1.5em;
}

.card__small__text span {
  content: "";
  display: block;
  background-color: #551a8b;
  padding: 0.4em;
  border-radius: 50%;
  margin-left: auto;
}



.card__primary__color {
  color: var(--asset-bg-color);
}

.card__text h2 {
  margin-bottom: 1em;
  font-size: 1.3rem;
  color: white;
  font-weight: 700;
}

.card__body {
  font-size: 1.15em;
  margin-bottom: 1em;
  width: 96%;
}

.card__tags {
  display: flex;
  align-items: center;
  gap: 1em;
}

.card__tags p {
  font-size: 1em;
  border-radius: 20px;
  padding: 0.3em 1.3em;
  border: 2px solid var(--asset-bg-color);
}

@media only screen and (max-width: 1800px) {
  .container img {
    width: 355px;
  }
}

@media only screen and (max-width: 1000px) {
  .container {
    width: 300px;
    flex-direction: column;
  }

  .container img {
    width: 255px;
  }
}

@media only screen and (max-width: 750px) {
  .container {
    width: 350px;
    flex-direction: column;
  }
}

@media only screen and (max-width: 650px) {
  .container {
    width: 484px;
    flex-direction: column;
    padding: 0;
  }

  .container img {
    width: 484px;
    margin-top: 1em;
  }

  .card__text {
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .container {
    width: 300px;
    flex-direction: column;
    padding: 0;
  }

  .container img {
    width: 290px;
    margin-top: 1em;
  }

  .card__text {
    width: 100%;
  }

  .card__small__text {
    font-size: 0.9rem;
    margin-bottom: 0.5em;
    align-items: center;
  }

  .card__text h2 {
    margin-bottom: 0.8em;
    font-size: 1.1rem;
  }

  .card__body {
    font-size: 1rem;
  }

  .card__small__text span {
    padding: 0.3em;
  }

  .card__tags p {
    font-size: 0.8rem;
  }
}
