.main_slider_container{
    width: 100%;
    height: 350px;
    position: relative;
    display: flex;
    align-items: center;
    
}

.slider{
    width: 100%;
    height: 100%;
    overflow-x: scroll;
    scrollbar-width: none;
    scroll-behavior: smooth;
}

.slider::-webkit-scrollbar{
    display: none;
}

.left_slider_icon{
    background-color: var(--asset-bg-color);
    border-radius: 30%;
    position: absolute;
    left: 0;
    cursor: pointer;
    color: var(--primary-text-color);
}

.right_slider_icon{
    background-color: var(--asset-bg-color);
    border-radius: 30%;
    position: absolute;
    right: 0;
    color: var(--primary-text-color);
    cursor: pointer;
}

.slider_card{
    width: 100%;
    height: 300px;
    border-radius: 10px;
    display: flex;
    
}

.slider__card_card{
    display: flex;
    gap: 10em;
}



.slider__card_card img{
    width: 160%;
    border-radius: 15px;
}

.slider__card_card h3{
    color: var(--primary-text-color);
    margin-top: 1em;
    width: 170%;
}

@media only screen and (max-width: 600px) {
    .main_slider_container{
        display: none;
    }
}