.header {
  color: var(--primary-text-color);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header h1 {
  font-size: 2.25rem;
  font-weight: 700;
  margin-top: 3.5em;
}
.header p {
  font-size: 1.25rem;
  margin-top: 8px;
}

.recent {
  margin-top: 5em;
}

.recent h2 {
  color: var(--asset-bg-color);
  font-size: 28px;
  margin-bottom: 0.8em;
}

.recent .posts {
  display: grid;
  gap: 1em;
  grid-template-columns: 3fr 5fr;
  grid-template-areas:
    "first other-one"
    "first other-two";
}

@media only screen and (max-width: 1000px) {
  .recent .posts {
    grid-template-columns: 1fr;
    grid-template-areas:
      "first"
      "other-one"
      "other-two";
  }
}
.first__card {
  grid-area: first;
  border: 3px solid transparent;
  border-radius: 59px 10px 40px 10px;
  padding: 1.3em 1em;
  position: relative;
  background-color: #000000;
  height: fit-content;
}

.first__card span {
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  background-image: linear-gradient(237.84deg, #000000 7.73%, #551a8b 37.18%, #c71585 56.75%, #000000 96.75%);
  z-index: -1;
  border-radius: 59px 10px 40px 10px;
}

.other__card:first-child {
  grid-area: other-one;
}
.other__card:last-child {
  grid-area: other-two;
}

@media screen and (max-width: 650px) {
  .recent .posts .other__card {
    flex-direction: column;
  }
  .recent .posts .other__card :global(.imgCtn) {
    width: 100%;
    height: 260px;
    overflow: hidden;
  }
}

.first__card .imgCtn {
  border-radius: 10px;
  width: 100%;
  height: 260px;
  overflow: hidden;
}

.first__card img {
  display: block;
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.all_posts {
  padding-block: 6em 3em;
}

.all_posts h2 {
  color: var(--secondary-bg-color);
  font-size: 1.55rem;
  margin-bottom: 0.8em;
}

.grid__posts {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* align-items: center; */
  gap: 60px;
}

@media screen and (max-width: 1000px) {
  .grid__posts {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 650px) {
  .grid__posts {
    grid-template-columns: 1fr;
  }
}

.blog__posts {
  margin-top: 13em;
  margin-bottom: 10em;
}

@media only screen and (max-width: 1000px) {
  /* .recent_blog_post{
        gap: 0.5em;
        flex-direction: column;
    } */

  .blog__content__text {
    margin-top: 3em;
  }

  .all__blog__posts section {
    width: 230px;
  }
}

@media only screen and (max-width: 650px) {
  .all__blog__posts {
    flex-direction: column;
    gap: 2em;
  }
}

@media only screen and (max-width: 500px) {
  .intro__head {
    font-size: 1.45rem;
  }

  .intro__text {
    font-size: 1rem;
  }

  .blog__content__text {
    margin-bottom: 0.5em;
  }

  .blog__posts {
    margin-top: 5em;
    margin-bottom: 7em;
  }

  .blog__posts h3 {
    margin-bottom: 1em;
  }

  .all__blog__posts {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  .all__blog__posts section {
    width: 300px;
    max-width: 300px;
    padding: 1em;
  }

  .all__blog__posts img {
    width: 284px;
  }

  /* .first__card{
        width: 340px;
        min-width: 340px;
        padding: 1em;
        text-align: justify;
        border: 2px solid #551A8B;
        margin: 1em;
        margin: 1em 0;
    }

    .first__card img{
        width: 300px;
        margin-top: .8em;
    } */
}
