

.timer__minutes{
    background: linear-gradient(99.83deg, #C71585 0.26%, #000000 7.44%, #000000 80.44%, #551A8B 99.9%);
    color: #fff;
    font-size: 1.8rem;
    text-align: center;
    border-radius: 10px;
    padding: .3em .8em;
    font-weight: 700px;
}

.container{
    display: flex;
    gap: 2em;
    margin-bottom: 2em;
}

.timer__minutes span{
    font-size: 1rem;
    color: #fff;
}

@media only screen and (max-width: 600px) {
    .timer__minutes{
        padding: .2em .6em;
    }

    .container{
        gap: 1.3em;
    }


}