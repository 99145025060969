.hero_img {
  width: 100%;
}

.first_section {
  color: var(--primary-text-color);
  text-align: center;
  margin-top: 2em;
  margin-bottom: 2em;
  /* width: 90%;
  margin-inline: auto; */
}

.container h1 {
  text-align: center;
  font-size: 2.7em;
  font-weight: 900;
  margin-bottom: 0.5em;
}

.container p {
  font-size: 1.2em;
  margin-bottom: 1.2em;
}

.ctn_text {
  margin-bottom: 1em;
}

.second_section {
  margin-top: 5em;
}

.second_section p {
  font-size: 1.3em;
  color: var(--primary-text-color);
  margin-bottom: 1em;
}

@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(calc(-100vw + 4em));
  }
}

.second_section img {
  max-height: 80px;
  max-width: 100%;
  display: inline-block;
  white-space: nowrap;
  border-top: 3px solid var(--secondary-bg-color);
  border-bottom: 3px solid var(--asset-bg-color);
}

.second_section img:first-child {
  animation: scroll 30s linear infinite;
}

.second_section img:last-child {
  animation: scroll 30s linear infinite;
}
.second_section img:nth-child(2) {
  animation: scroll 30s linear infinite;
}
.second_section img:nth-child(3) {
  animation: scroll 30s linear infinite;
}
.second_section img:nth-child(4) {
  animation: scroll 30s linear infinite;
}

.second_section div {
  width: 100%;
  overflow: hidden;
  margin-top: 4em;
  display: flex;
}

.third_section {
  color: var(--primary-text-color);
  margin-top: 10em;
  /* width: 90%;
  margin-inline: auto; */
}

.third_section h1 {
  margin-bottom: 2em;
  font-size: 2.75rem;
}

.third__section__flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4em;
  margin-bottom: 4em;
  text-align: center;
}

.third__section__flex div {
  border: 3px solid transparent;
  padding: 2em;
  position: relative;
  background: #000;
  border-radius: 70px 20px 50px 20px;
}

.third__section__flex div span {
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  z-index: -1;
  background-image: linear-gradient(237.84deg, #000000 7.73%, #551a8b 37.18%, #c71585 56.75%, #000000 96.75%);
  border-radius: 70px 20px 50px 20px;
}


.third__section__flex div h2 {
  margin-bottom: 2em;
  font-size: 1.5em;
}

.third__section__flex div p {
  font-size: 20px;
}

.fourth_section {
  margin-top: 13em;
  border-radius: 20px;
  background: linear-gradient(135deg, #c71585 0%, #000 50.03%, #551a8b 97.23%);
  padding: 5em 2em;
  margin-bottom: 0;
  text-align: center;
  width: 75%;
  margin-inline: auto;
}

.fourth_section h1 {
  color: var(--primary-text-color);
  opacity: 1;
  margin-bottom: 1em;
  font-size: 36px;
}

.fourth_section a {
  border: 1px solid #C71585;
  border-radius: 5px;
  padding: 0.75em 2.6em;
  font-size: 1.15rem;
  background: transparent;
  color: var(--primary-text-color);
  cursor: pointer;
  display: inline-block;
}

.pink_blur {
  filter: blur(300px);
  background-color: color-mix(in srgb, var(--asset-bg-color) 70%, #000 10%);
  width: 473px;
  height: 710px;
  border-radius: 710px;
  display: block;
  margin-top: -2em;
  margin-right: 4em;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.purple_blur {
  filter: blur(300px);
  background-color: var(--secondary-bg-color);
  width: 473px;
  height: 710px;
  border-radius: 710px;
  position: absolute;
  margin-top: 4em;
  margin-left: 4em;
  right: 0;
  z-index: -1;
}

@media only screen and (max-width: 995px) {
  .third__section__flex {
    gap: 2em;
  }

  .third__section__flex div {
    padding: 1em;
  }
}

@media only screen and (max-width: 780px) {
  .third__section__flex {
    display: block;
    gap: 2em;
  }

  .third__section__flex div {
    padding: 1em;
    margin-bottom: 2em;
  }

  .ctn_text h1 {
    font-size: 2.5rem;
  }

  .second_section img {
    width: 170%;
  }

  .pink_blur {
    margin: 0;
    top: 0;
    left: 0;
    width: 350px;
  }

  .purple_blur {
    margin: 0;
    left: 0em;
    top: 10em;
    width: 300px;
  }
}

@media only screen and (max-width: 600px) {
  .hero_img {
    display: none;
  }

  .ctn_text {
    margin-top: 8em;
    margin-bottom: 15em;
  }

  .ctn_text p {
    font-size: 1rem;
    margin-top: 0.5em;
  }

  .ctn_text h1 {
    font-size: 2rem;
  }

  .second_section div {
    margin-top: 0;
  }

  .second_section img {
    width: 170%;
  }
}

@media only screen and (max-width: 430px) {
  .hero_img {
    display: none;
  }

  .ctn_text {
    margin-top: 8em;
    margin-bottom: 15em;
  }

  .ctn_text h1 {
    font-size: 2.2rem;
  }

  .ctn_text p {
    font-size: 1.2rem;
    margin-top: 0.5em;
  }

  .second_section div {
    margin-top: 0;
  }

  .second_section img {
    width: 150%;
  }

  .third_section h1 {
    font-size: 1.7rem;
  }

  .fourth_section {
    margin-top: 10em;
  }

  .fourth_section h1 {
    font-size: 1.8rem;
    margin-bottom: 2em;
  }

  .fourth_section a {
    border: 2px solid var(--asset-bg-color);
    padding: 0.9em 1.8em;
    font-size: 1rem;
  }
}

.ctab {
  transition: scale 0.2s ease-in-out;
}

.ctab:hover {
  scale: 1.1;
}

.linkBtn {
  transition: background-color 0.2s ease-in-out;
}

.linkBtn:hover {
  background-color: var(--asset-bg-color);
}
